import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactGlobal from "components/ContactGlobal"

import { Hero, Content, InfoStandalone } from "page_components/apartment"

const Apartment = ({ pageContext }) => {
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  return (
    <Layout
      seo={{
        title: pageContext?.data?.data?.seo.title
          ? pageContext?.data?.data?.seo.title
          : pageContext?.data?.title,
        description: pageContext?.data?.data?.seo.metaDesc
          ? pageContext?.data?.data?.seo.metaDesc
          : "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
    >
      <Breadcrumbs
        title={pageContext?.data?.title}
        parent={{
          title: "Portfel nieruchomości",
          path: "/portfel-nieruchomosci/",
        }}
      />
      <Hero
        id={pageContext?.data?.id}
        title={pageContext?.data?.title}
        data={pageContext?.data?.data?.acfApartment}
        standAlone
      />
      <Content data={pageContext?.data?.data?.acfApartment} />
      <InfoStandalone data={pageContext?.data?.data?.acfApartment} />
      <ContactGlobal />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Apartment
